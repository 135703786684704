import { colorPalette } from "gx-npm-common-styles";

const silverColor = colorPalette.neutrals.silver.hex;
const sweetGrapeColor = colorPalette.accent.sweetGrape.hex;
const cherryColor = colorPalette.status.cherry.hex;

const responseOptionsStyles = {
  checkMark: { paddingRight: 20 },
  divider: { borderBottom: `1px solid ${silverColor}`, marginBottom: 28 },
  infoRoot: {
    display: "flex",
    gap: 16,
    "& $info": { color: sweetGrapeColor },
  },
  responseOptions: {
    paddingBottom: 26,
    "&:after": { content: "'*'", color: cherryColor, paddingLeft: 4 },
  },
  info: {},
};

export default responseOptionsStyles;
