import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Checkbox, TypographyComponent } from "gx-npm-ui";
import { questionsCategoriesListItemStyles as styles } from "./questions-categories-list.styles";
import { CategoryType } from "../../form.types";
import classNames from "classnames";

type QuestionsCategoriesListItemComponentProps = {
  category: CategoryType;
  onCategoryListItemChange?: (category: CategoryType, id: string, isSelected: boolean) => void;
  isSelected?: boolean;
};

const useStyles = makeStyles(() => styles);

const QuestionsCategoriesListItemComponent = ({
  category,
  onCategoryListItemChange,
  isSelected = false,
}: QuestionsCategoriesListItemComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { id, itemCount, name } = category;

  return (
    <li
      aria-label={t("category of question to add to questionnaire")}
      className={classes.categoriesListItem}
      key={`category-${id}`}
    >
      <Checkbox checked={isSelected} label={name} onChange={onCategoryListItemChange} />
      <TypographyComponent element={"p"}>
        <TypographyComponent element={"span"} rootClassName={classNames(classes.categoriesRequirementsCount)}>
          {itemCount}
        </TypographyComponent>{" "}
        {`${t("Requirement")}${itemCount !== 1 ? "s" : ""}`}
      </TypographyComponent>
    </li>
  );
};

export default QuestionsCategoriesListItemComponent;
