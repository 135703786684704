import React from "react";
import { useTranslation } from "react-i18next";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "../sending-the-questionnaire/sending-the-questionnaire.styles.module.scss";
import SendingTheQuestionnaireImg from "../../../../assets/images/quick-start/sendingTheQuestionnaire.svg";

const SendingTheQuestionnaireComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TypographyComponent type={"h5"} boldness={"semi"}>
        {t("Sending the questionnaire")}
      </TypographyComponent>
      <div className={styles.sendQuestionnaireImageContainer}>
        <img src={SendingTheQuestionnaireImg} alt={t("Sending the questionnaire")} className={styles.imgSize} />
      </div>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
          {t(
            "Once your questionnaire has been created, you can send the questionnaire to the vendor contacts you’ve been working with."
          )}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
          {t(
            "You may only set up a maximum of one questionnaire, but it can be sent to as many vendor contacts as you see fit. You can get a preview of what vendors will receive by using the ‘Preview’ option that can be found in the Questionnaire options dropdown menu."
          )}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about managing questionnaire access")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/8226140-managing-questionnaire-access"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SendingTheQuestionnaireComponent;
