import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { formSectionAboutStyles as styles } from "./form-section-about.styles";
import { TypographyComponent } from "gx-npm-ui";

type FormSectionAboutProps = {
  description?: string;
  rootClassName?: string;
  title?: string;
};

const useStyles = makeStyles(() => styles);

const FormSectionAbout = ({ description = "", rootClassName = "", title = "" }: FormSectionAboutProps) => {
  const classes = useStyles();

  return (
    <div aria-label={"title and description"} className={classNames(classes.container, rootClassName && rootClassName)}>
      <TypographyComponent
        styling={"h4"}
        boldness={"medium"}
        color={"carbon"}
        rootClassName={classNames(classes.title)}
      >
        {title}
      </TypographyComponent>
      <TypographyComponent
        element={"p"}
        boldness={"regular"}
        color={"iron"}
        rootClassName={classNames(classes.description)}
      >
        {description}
      </TypographyComponent>
    </div>
  );
};

export default FormSectionAbout;
