// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XNULyXJsQ4Q4jP0E7yl9{padding-right:81px}.XNULyXJsQ4Q4jP0E7yl9 .MGS9whpGP3sF9o8ktR_V{padding-bottom:56px}.XNULyXJsQ4Q4jP0E7yl9 .TWo_dhgBR6ELN0INMpJn{display:flex;align-items:center;gap:15px;padding-bottom:12px}.XNULyXJsQ4Q4jP0E7yl9 .zFKdRv6yHUNHPVuQVLlU{color:var(--color-neutrals-quartz);width:400px}.XNULyXJsQ4Q4jP0E7yl9 .m2_jou03otSuQOnwH_kR{width:400px;margin-bottom:56px}.XNULyXJsQ4Q4jP0E7yl9 .rnTiKr8ZsLDCZX5EkVSg{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.XNULyXJsQ4Q4jP0E7yl9 .EPkk1x4OJWddGFP_xZgT{margin-bottom:16px}.XNULyXJsQ4Q4jP0E7yl9 .CUDH0HOZbnkFPOCpEJkk .W1DJvBVirBenI6nyNjDw{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,kEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `XNULyXJsQ4Q4jP0E7yl9`,
	"gx-gartner-mg-desc-text": `MGS9whpGP3sF9o8ktR_V`,
	"contentBodyMidSectionTitleText": `TWo_dhgBR6ELN0INMpJn`,
	"contentBodyMidSectionDescText": `zFKdRv6yHUNHPVuQVLlU`,
	"contentBodyMidSectionDescTextGCOM3523": `m2_jou03otSuQOnwH_kR`,
	"contentLearnMoreLine": `rnTiKr8ZsLDCZX5EkVSg`,
	"contentBodyMidSectionLearnMore": `EPkk1x4OJWddGFP_xZgT`,
	"contentLink": `CUDH0HOZbnkFPOCpEJkk`,
	"contentBodyMidSectionLinkText": `W1DJvBVirBenI6nyNjDw`
};
export default ___CSS_LOADER_EXPORT___;
