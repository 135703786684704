import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IconMessage } from "../../../../ui/icons/form.icons";
import { TypographyComponent } from "gx-npm-ui";
import { useFormContext } from "../../context/form.context";
import FormSection from "../form-section/form-section.component";
import FormSectionAbout from "../form-section/form-section-about.component";
import FormSectionLeft from "../form-section/form-section-left.component";
import FormSectionRight from "../form-section/form-section-right.component";
import FormSectionStatus from "../form-section/form-section-status.component";
import styles from "./response-options.styles";
import ResponseOptionsList from "./response-options-list.component";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
const ResponseOptionsComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { responseOptions } = useFormContext();

  return (
    <div aria-label={t("custom response options")}>
      <FormSection>
        <FormSectionLeft>
          <FormSectionStatus isComplete={responseOptions.length > 0} />
          <FormSectionAbout
            title={t("Customize response options")}
            description={t(
              "Manage how you want recipients to respond to each requirement (e.g. fully supports, partially supports, doesn't support)."
            )}
          />
          <div className={classes.divider} />
          <div className={classes.infoRoot}>
            <div>
              <IconMessage />
            </div>
            <TypographyComponent element={"p"} color={"sweetGrape"}>
              {t("Recipients will have the ability to leave an optional, short text response for each requirement.")}
            </TypographyComponent>
          </div>
        </FormSectionLeft>
        <FormSectionRight>
          <TypographyComponent
            boldness={"semi"}
            element={"p"}
            color={"coal"}
            rootClassName={classNames(classes.responseOptions)}
          >
            {t("Response options")}
          </TypographyComponent>
          <ResponseOptionsList />
        </FormSectionRight>
      </FormSection>
    </div>
  );
};

export default ResponseOptionsComponent;
