import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCherry = colorPalette.status.cherry.hex;
const colorSilver = colorPalette.neutrals.silver.hex;
const colorWhite = colorPalette.basic.white.hex;

const weightVarMedium = weightPalette.medium.variation;
const weightAmtMedium = weightPalette.medium.amount;
const weightVarSemiBold = weightPalette.semiBold.variation;
const weightAmtSemiBold = weightPalette.semiBold.amount;

const questionsCategoriesListStyles = {
  categoriesListRoot: {
    "& $categoriesList": {
      padding: 0,
    },
    "& $categoriesSelected": {
      backgroundColor: colorSilver,
      borderRadius: 4,
      display: "flex",
      justifyContent: "space-between",
      padding: "23px 24px 23px 24px",
    },
    "& $checkBoxSelectAll": { paddingLeft: 19 },
    "& $categoriesRequirement": {
      paddingBottom: 26,
      "&:after": { content: "'*'", color: colorCherry, paddingLeft: 4 },
    },
    "& $categoriesSelectedCount,& $requirementsSelectedCount": { paddingRight: 5 },
  },

  categoriesList: {},
  categoriesRequirement: {},
  categoriesSelected: {},
  categoriesSelectedCount: {},
  checkBoxSelectAll: {},
  requirementsSelectedCount: {},
};

const questionsCategoriesListItemStyles = {
  categoriesListItem: {
    alignItems: "center",
    backgroundColor: colorWhite,
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    listStyleType: "none",
    marginBottom: 16,
    padding: "11px 82px 11px 19px",
    "& .gx-checkbox-label": {
      fontVariationSettings: weightVarMedium,
      fontWeight: weightAmtMedium,
      paddingLeft: 14,
    },
  },
  categoriesRequirementsCount: {
    fontVariationSettings: weightVarSemiBold,
    fontWeight: weightAmtSemiBold,
    paddingRight: 5,
  },
};

export { questionsCategoriesListStyles, questionsCategoriesListItemStyles };
